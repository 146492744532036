<template>
  <div class="mangopay">
    <div class="top">
      <img src="@/assets/svg/mangopayGrey.svg" alt="Mangopay" class="themed-img"/>
    </div>
    <vue-pdf-embed :source="source"></vue-pdf-embed>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      source:
        'https://u8db3x7279cw66r74nmk.s3.eu-west-3.amazonaws.com/PSP_MANGOPAY_FR.pdf',
    }
  },
}
</script>

<style scoped lang="scss">
.top {
  width: 100%;
  margin: 50px auto;
  width: 200px;
}
.mangopay .top {
  display: flex;
  justify-content: center;
  align-self: center;

  img {
    max-width: 200px;
  }
}

.mangopay {
  max-width: 1000px;
  width: 95%;
  margin: auto;
}

@media screen and (max-width: 500px) {
  img {
    width: 250px;
  }
}
</style>
